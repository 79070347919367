<template>
  <CreateEditMovimentacaoModal
    v-if="showCreateEditMovimentacaoModal"
    :idMovimentacao="idMovimentacao"
    @closeModal="closeAllModal"
  />
  <DeleteMovimentacaoModal
    v-if="showDeleteMovimentacaoModal"
    :idMovimentacao="idMovimentacao"
    @closeModal="closeAllModal"
  />
  <AprovationMovimentacaoModal
    v-if="showAprovationMovimentacaoModal"
    :idMovimentacao="idMovimentacao"
    @closeModal="closeAllModal"
  />
  <LoadingData v-if="isLoadingData" />
  <div v-else class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h6 class="py-3 mb-0">Detalhes da Movimentação</h6>
      <div class="ms-auto">
        <router-link
          to="/movimentacoes"
          class="btn btn-outline-dark"
          title="Listagem de Movimentações"
        >
          Listagem de Movimentações
        </router-link>
      </div>
    </div>
    <div>
      <div id="accordion_collapsed" class="accordion">
        <DetailsMovimentacaoAccordion :movimentacao="movimentacao" />
        <DetailsProcessoSeletivoAccordion :movimentacao="movimentacao" />
      </div>
    </div>
    <div class="card-footer d-flex justify-content-between align-items-center">
      <ul class="list-inline text-muted mb-0">
        <li class="list-inline-item">
          Cadastrado:
          {{
            createdUpdatedUser(movimentacao.CRIADOPOR, movimentacao.CRIADOEM)
          }}
        </li>
        <li class="list-inline-item">
          Atualizado por:
          {{
            createdUpdatedUser(
              movimentacao.ATUALIZADOPOR,
              movimentacao.ATUALIZADOEM,
            )
          }}
        </li>
      </ul>
      <div class="w-50 text-end">
        <button
          type="button"
          class="btn btn-outline-danger btn-icon"
          title="Excluir"
          @click="showDeleteMovimentacaoModal = true"
        >
          <i class="ph ph-trash" />
        </button>
        <button
          type="button"
          class="btn btn-outline-warning btn-icon ms-2"
          title="Editar"
          @click="showCreateEditMovimentacaoModal = true"
        >
          <i class="ph ph-pencil" />
        </button>
        <button
          v-if="
            user.isAdmin &&
            StatusMovimentacaoEnum.AGUARDANDO_INICIO === movimentacao.STATUS
          "
          type="button"
          class="btn btn-outline-success btn-icon ms-2"
          title="Validar Movimentação"
          @click="showAprovationMovimentacaoModal = true"
        >
          <i class="ph ph-check" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CreateEditMovimentacaoModal from '@/components/movimentacoes/movimentacoes/CreateEditMovimentacaoModal.vue'
import DeleteMovimentacaoModal from '@/components/movimentacoes/movimentacoes/DeleteMovimentacaoModal.vue'
import AprovationMovimentacaoModal from '@/components/movimentacoes/movimentacoes/AprovationMovimentacaoModal.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import DetailsMovimentacaoAccordion from '@/components/movimentacoes/movimentacoes/DetailsMovimentacaoAccordion.vue'
import DetailsProcessoSeletivoAccordion from '@/components/movimentacoes/processoSeletivo/DetailsProcessoSeletivoAccordion.vue'
import { mapGetters } from 'vuex'
import StatusMovimentacaoEnum from '@/enums/statusMovimentacaoEnum'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'DetailsMovimentacao',

  components: {
    CreateEditMovimentacaoModal,
    DeleteMovimentacaoModal,
    AprovationMovimentacaoModal,
    LoadingData,
    DetailsMovimentacaoAccordion,
    DetailsProcessoSeletivoAccordion,
  },

  data() {
    return {
      idMovimentacao: this.$route.params.idMovimentacao,
      isLoadingData: true,
      showCreateEditMovimentacaoModal: false,
      showDeleteMovimentacaoModal: false,
      showAprovationMovimentacaoModal: false,
      movimentacao: {},
      StatusMovimentacaoEnum,
      createdUpdatedUser,
    }
  },

  computed: {
    ...mapGetters(['user']),
  },

  methods: {
    async getMovimentacao() {
      try {
        this.movimentacao = await this.$store.dispatch(
          'getMovimentacao',
          this.idMovimentacao,
        )
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }

        this.$router.push({ name: 'Movimentacoes' })
      }
    },

    closeAllModal(needReload) {
      if (needReload) {
        this.getMovimentacao()
      }
      this.showCreateEditMovimentacaoModal = false
      this.showDeleteMovimentacaoModal = false
      this.showAprovationMovimentacaoModal = false
    },
  },

  mounted() {
    this.getMovimentacao()
  },
}
</script>
