<template>
  <table class="table table-striped table-sm table-bordered" :id="dataTable.id">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>Unidade</th>
        <th>Tipo de Movimentação</th>
        <th>Justificativa</th>
        <th>Criada Por</th>
        <th>Criada Em</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="movimentacao in movimentacoes"
        :key="movimentacao.IDMOVIMENTACAO"
      >
        <td class="text-center">{{ movimentacao.IDMOVIMENTACAO }}</td>
        <td>{{ movimentacao.NOMEUNIDADE }}</td>
        <td>{{ movimentacao.NOMETIPOMOVIMENTACAO }}</td>
        <td>{{ movimentacao.JUSTIFICATIVA }}</td>
        <td>{{ movimentacao.CRIADOPOR }}</td>
        <td>{{ dateTimeSqlToDateTimeBR(movimentacao.CRIADOEM) }}</td>
        <td
          v-html="statusMovimentacaoTranslate(movimentacao.STATUS)"
          class="text-center"
        />
        <td class="text-center p-2">
          <router-link
            :to="`movimentacoes/${movimentacao.IDMOVIMENTACAO}`"
            class="btn btn-outline-primary btn-icon"
            title="Detalhes"
          >
            <i class="ph ph-file-text" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import '@/utils/basicDataTableExtend'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import { statusMovimentacaoTranslate } from '@/utils/strings'

export default {
  name: 'ListMovimentacoes',

  props: {
    movimentacoes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: null,
      dataTable: {
        id: 'dataTableListMovimentacoes',
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: [5],
            orderable: false,
          },
          {
            targets: [7],
            width: 130,
          },
        ],
      },
      dateTimeSqlToDateTimeBR,
      statusMovimentacaoTranslate,
    }
  },

  methods: {
    createDataTable() {
      if (this.table) {
        this.table.destroy()
      }
      this.table = window.$(`#${this.dataTable.id}`).DataTable({
        order: this.dataTable.order,
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
