<template>
  <div class="accordion-item border-top-0 border-start-0 border-end-0">
    <h2 class="accordion-header" title="Dados Básicos">
      <button
        type="button"
        class="accordion-button fw-semibold"
        data-bs-toggle="collapse"
        data-bs-target="#dados-movimentacao"
      >
        Dados Básicos
      </button>
    </h2>
    <div id="dados-movimentacao" class="accordion-collapse collapse show">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p><strong>Unidade:</strong> {{ movimentacao.NOMEUNIDADE }}</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p>
              <strong>Tipo de Movimentação:</strong>
              {{ movimentacao.NOMETIPOMOVIMENTACAO }}
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p>
              <strong>Solicitante:</strong>
              {{ movimentacao.CRIADOPOR }} em
              {{ dateTimeSqlToDateTimeBR(movimentacao.CRIADOEM) }}
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p>
              <strong>Status: </strong>
              <span v-html="statusMovimentacaoTranslate(movimentacao.STATUS)" />
            </p>
          </div>
          <div class="col-sm-12">
            <p class="mb-0">
              <strong>Justificativa:</strong>
              {{ movimentacao.JUSTIFICATIVA }}
            </p>
          </div>
        </div>
        <div v-if="showValidation">
          <hr />
          <div class="row">
            <div class="col-sm-12">
              <p>
                <strong>Validação:</strong>
                {{ movimentacao.VALIDADOPOR }} em
                {{ dateTimeSqlToDateTimeBR(movimentacao.VALIDADOEM) }}
              </p>
            </div>
            <div class="col-sm-12">
              <p class="mb-0">
                <strong>Comentário:</strong>
                {{ movimentacao.COMENTARIO }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusMovimentacaoEnum from '@/enums/statusMovimentacaoEnum'
import { statusMovimentacaoTranslate } from '@/utils/strings'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'

export default {
  name: 'DetailsMovimentacaoAccordion',

  props: {
    movimentacao: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      statusMovimentacaoTranslate,
      dateTimeSqlToDateTimeBR,
    }
  },

  computed: {
    showValidation() {
      return (
        StatusMovimentacaoEnum.REPROVADO === this.movimentacao.STATUS ||
        StatusMovimentacaoEnum.APROVADO === this.movimentacao.STATUS
      )
    },
  },
}
</script>
