<template>
  <div id="modalAprovationMovimentacao" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Validar Movimentação</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="aprovationMovimentacao">
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-sm-12">
                <label class="form-label">
                  Situação: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.movimentacao.STATUS.$errors.length,
                  }"
                  v-model="v$.movimentacao.STATUS.$model"
                >
                  <option value="" disabled>Selecione</option>
                  <option
                    v-for="option in statusOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <span
                  v-if="
                    v$.movimentacao.STATUS.required.$invalid &&
                    v$.movimentacao.STATUS.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12">
                <label class="form-label">
                  Comentários:
                  <span
                    v-if="
                      StatusMovimentacaoEnum.REPROVADO === movimentacao.STATUS
                    "
                    class="text-danger"
                  >
                    *
                  </span>
                </label>
                <textarea
                  class="form-control"
                  :class="{
                    'is-invalid': v$.movimentacao.COMENTARIO.$errors.length,
                  }"
                  rows="4"
                  placeholder="Comentários"
                  v-model="v$.movimentacao.COMENTARIO.$model"
                />
                <span
                  v-if="
                    v$.movimentacao.COMENTARIO.requiredIf.$invalid &&
                    v$.movimentacao.COMENTARIO.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Confirmar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph ph-spinner spinner" />
              </span>
              <span v-else>Confirmar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import StatusMovimentacaoEnum from '@/enums/statusMovimentacaoEnum'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'AprovationMovimentacaoModal.vue',

  props: {
    idMovimentacao: {
      type: [String, Number],
      required: true,
    },
  },

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      movimentacao: {
        STATUS: '',
        COMENTARIO: '',
      },
      statusOptions: [
        { value: 2, text: 'Aprovado' },
        { value: 0, text: 'Reprovado' },
      ],
      StatusMovimentacaoEnum,
      disableButton: false,
    }
  },

  methods: {
    async aprovationMovimentacao() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch('aprovationMovimentacao', {
          idMovimentacao: this.idMovimentacao,
          movimentacao: this.movimentacao,
        })

        hideModal()
        this.$emit('closeModal', true)
        this.$root.$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  validations() {
    return {
      movimentacao: {
        STATUS: {
          required,
        },
        COMENTARIO: {
          requiredIf: requiredIf(
            StatusMovimentacaoEnum.REPROVADO === this.movimentacao.STATUS,
          ),
        },
      },
    }
  },

  mounted() {
    showModal('modalAprovationMovimentacao')
  },
}
</script>
