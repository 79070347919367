<template>
  <div id="modalCreateEditMovimentacao" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-full">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ textModal }} Movimentação</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <LoadingData v-if="isLoadingData" class="my-3" />
        <form v-else @submit.prevent="save">
          <div id="accordion_collapsed" class="accordion">
            <!--DADOS BASICOS-->
            <div class="accordion-item border-top-0">
              <h2 class="accordion-header" title="Dados Básicos">
                <button
                  type="button"
                  class="accordion-button fw-semibold"
                  data-bs-toggle="collapse"
                  data-bs-target="#dados-movimentacao"
                >
                  Dados Básicos
                </button>
              </h2>
              <div
                id="dados-movimentacao"
                class="accordion-collapse collapse show"
              >
                <div class="accordion-body">
                  <div class="row g-3">
                    <div class="col-sm-6">
                      <label class="form-label">
                        Unidade: <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-select"
                        :class="{
                          'is-invalid':
                            v$.movimentacao.IDUNIDADE.$errors.length,
                        }"
                        v-model="v$.movimentacao.IDUNIDADE.$model"
                      >
                        <option value="" disabled>Selecione</option>
                        <option
                          v-for="unidade in unidadesOptions"
                          :key="unidade.IDUNIDADE"
                          :value="unidade.IDUNIDADE"
                        >
                          {{ unidade.NOME }}
                        </option>
                      </select>
                      <span
                        v-if="
                          v$.movimentacao.IDUNIDADE.required.$invalid &&
                          v$.movimentacao.IDUNIDADE.$errors.length
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">
                        Tipo de Movimentação:
                        <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-select"
                        :class="{
                          'is-invalid':
                            v$.movimentacao.IDTIPOMOVIMENTACAO.$errors.length,
                        }"
                        v-model="v$.movimentacao.IDTIPOMOVIMENTACAO.$model"
                      >
                        <option value="" disabled>Selecione</option>
                        <option
                          v-for="tipoMovimentacao in tipoMovimentacoesOptions"
                          :key="tipoMovimentacao.IDTIPOMOVIMENTACAO"
                          :value="tipoMovimentacao.IDTIPOMOVIMENTACAO"
                        >
                          {{ tipoMovimentacao.NOME }}
                        </option>
                      </select>
                      <span
                        v-if="
                          v$.movimentacao.IDTIPOMOVIMENTACAO.required
                            .$invalid &&
                          v$.movimentacao.IDTIPOMOVIMENTACAO.$errors.length
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="col-sm-12">
                      <label class="form-label">
                        Justificativa: <span class="text-danger">*</span>
                      </label>
                      <textarea
                        class="form-control"
                        :class="{
                          'is-invalid':
                            v$.movimentacao.JUSTIFICATIVA.$errors.length,
                        }"
                        rows="4"
                        placeholder="Justificativa"
                        v-model="v$.movimentacao.JUSTIFICATIVA.$model"
                      />
                      <span
                        v-if="
                          v$.movimentacao.JUSTIFICATIVA.required.$invalid &&
                          v$.movimentacao.JUSTIFICATIVA.$errors.length
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--PROCESSO SELETIVO-->
            <div
              v-if="
                TipoMovimentacaoEnum.PROCESSO_SELETIVO ===
                tipoMovimentacaoSelected.IDTIPOMOVIMENTACAO
              "
              class="accordion-item"
            >
              <h2 class="accordion-header" title="Processo Seletivo">
                <button
                  type="button"
                  class="accordion-button fw-semibold"
                  data-bs-toggle="collapse"
                  data-bs-target="#processo-seletivo"
                >
                  Processo Seletivo
                </button>
              </h2>
              <div
                id="processo-seletivo"
                class="accordion-collapse collapse show"
              >
                <div class="accordion-body">
                  <div class="row g-3">
                    <div class="col-sm-6">
                      <label class="form-label">
                        Nome do Processo Seletivo:
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            v$.movimentacao.PROCESSO_SELETIVO.NOME.$errors
                              .length,
                        }"
                        placeholder="Nome do Processo Seletivo"
                        v-model="v$.movimentacao.PROCESSO_SELETIVO.NOME.$model"
                      />
                      <span
                        v-if="
                          v$.movimentacao.PROCESSO_SELETIVO.NOME.requiredIfPS
                            .$invalid &&
                          v$.movimentacao.PROCESSO_SELETIVO.NOME.$errors.length
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">
                        Função:
                        <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-select"
                        :class="{
                          'is-invalid':
                            v$.movimentacao.PROCESSO_SELETIVO.IDFUNCAO.$errors
                              .length,
                        }"
                        v-model="
                          v$.movimentacao.PROCESSO_SELETIVO.IDFUNCAO.$model
                        "
                      >
                        <option value="" disabled>Selecione</option>
                        <option
                          v-for="funcao in funcoesOptions"
                          :key="funcao.IDFUNCAO"
                          :value="funcao.IDFUNCAO"
                        >
                          {{ funcao.NOME }}
                        </option>
                      </select>
                      <span
                        v-if="
                          v$.movimentacao.PROCESSO_SELETIVO.IDFUNCAO
                            .requiredIfPS.$invalid &&
                          v$.movimentacao.PROCESSO_SELETIVO.IDFUNCAO.$errors
                            .length
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">
                        Data Prevista para Início:
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            v$.movimentacao.PROCESSO_SELETIVO.DATAINICIOPREVISTA
                              .$errors.length,
                        }"
                        placeholder="Nome do Processo Seletivo"
                        v-model="
                          v$.movimentacao.PROCESSO_SELETIVO.DATAINICIOPREVISTA
                            .$model
                        "
                      />
                      <span
                        v-if="
                          v$.movimentacao.PROCESSO_SELETIVO.DATAINICIOPREVISTA
                            .requiredIfPS.$invalid &&
                          v$.movimentacao.PROCESSO_SELETIVO.DATAINICIOPREVISTA
                            .$errors.length
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">
                        Tipo de Vaga: <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-select"
                        :class="{
                          'is-invalid':
                            v$.movimentacao.PROCESSO_SELETIVO.IDTIPOVAGA.$errors
                              .length,
                        }"
                        v-model="
                          v$.movimentacao.PROCESSO_SELETIVO.IDTIPOVAGA.$model
                        "
                      >
                        <option value="" disabled>Selecione</option>
                        <option
                          v-for="tipoVaga in tipoVagasOptions"
                          :key="tipoVaga.IDTIPOVAGA"
                          :value="tipoVaga.IDTIPOVAGA"
                        >
                          {{ tipoVaga.NOME }}
                        </option>
                      </select>
                      <span
                        v-if="
                          v$.movimentacao.PROCESSO_SELETIVO.IDTIPOVAGA
                            .requiredIfPS.$invalid &&
                          v$.movimentacao.PROCESSO_SELETIVO.IDTIPOVAGA.$errors
                            .length
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="col-sm-12">
                      <label class="form-label"> Observações: </label>
                      <textarea
                        class="form-control"
                        rows="4"
                        placeholder="Observações"
                        v-model="movimentacao.PROCESSO_SELETIVO.OBSERVACAO"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--PROMOCAO-->
            <div
              v-if="
                TipoMovimentacaoEnum.PROMOCAO ===
                tipoMovimentacaoSelected.IDTIPOMOVIMENTACAO
              "
              class="accordion-item"
            >
              <h2 class="accordion-header" title="Promoção">
                <button
                  type="button"
                  class="accordion-button fw-semibold"
                  data-bs-toggle="collapse"
                  data-bs-target="#promocao"
                >
                  Promoção
                </button>
              </h2>
              <div id="promocao" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <p class="text-center mb-0">
                    Tipo de movimentação ainda não disponível no sistema
                  </p>
                </div>
              </div>
            </div>
            <!--TRANSFERENCIA-->
            <div
              v-if="
                TipoMovimentacaoEnum.TRANSFERENCIA ===
                tipoMovimentacaoSelected.IDTIPOMOVIMENTACAO
              "
              class="accordion-item"
            >
              <h2 class="accordion-header" title="Transferência">
                <button
                  type="button"
                  class="accordion-button fw-semibold"
                  data-bs-toggle="collapse"
                  data-bs-target="#transferencia"
                >
                  Transferência
                </button>
              </h2>
              <div id="transferencia" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <p class="text-center mb-0">
                    Tipo de movimentação ainda não disponível no sistema
                  </p>
                </div>
              </div>
            </div>
            <!--DESLIGAMENTO-->
            <div
              v-if="
                TipoMovimentacaoEnum.DESLIGAMENTO ===
                tipoMovimentacaoSelected.IDTIPOMOVIMENTACAO
              "
              class="accordion-item"
            >
              <h2 class="accordion-header" title="Desligamento">
                <button
                  type="button"
                  class="accordion-button fw-semibold"
                  data-bs-toggle="collapse"
                  data-bs-target="#desligamento"
                >
                  Desligamento
                </button>
              </h2>
              <div id="desligamento" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <p class="text-center mb-0">
                    Tipo de movimentação ainda não disponível no sistema
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph ph-spinner spinner" />
              </span>
              <span v-else>{{ textModal }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import TipoMovimentacaoEnum from '@/enums/tipoMovimentacaoEnum'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'CreateEditMovimentacaoModal',

  props: {
    idMovimentacao: {
      type: [String, Number],
      default: null,
    },
  },

  components: {
    LoadingData,
  },

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      isLoadingData: true,
      movimentacao: {
        IDUNIDADE: '',
        IDTIPOMOVIMENTACAO: '',
        JUSTIFICATIVA: '',
        PROCESSO_SELETIVO: {
          IDTIPOVAGA: '',
          IDFUNCAO: '',
          NOME: '',
          DATAINICIOPREVISTA: '',
          OBSERVACAO: '',
        },
      },
      unidadesOptions: [],
      tipoMovimentacoesOptions: [],
      tipoVagasOptions: [],
      funcoesOptions: [],
      tipoMovimentacaoSelected: {
        IDTIPOMOVIMENTACAO: '',
        NOME: '',
      },
      disableButton: false,
      TipoMovimentacaoEnum,
    }
  },

  computed: {
    textModal() {
      return this.idMovimentacao ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'movimentacao.IDTIPOMOVIMENTACAO': {
      handler(newVal, oldVal) {
        if (newVal) {
          const { IDTIPOMOVIMENTACAO, NOME } =
            this.tipoMovimentacoesOptions.find(
              (tipoMovimentacao) =>
                tipoMovimentacao.IDTIPOMOVIMENTACAO === newVal,
            )
          this.tipoMovimentacaoSelected = { IDTIPOMOVIMENTACAO, NOME }
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getUnidadesAndTipoMovimentacoesAndTipoVagaAndFuncoes() {
      try {
        this.unidadesOptions = await this.$store.dispatch('getUnidadesAtivas')
        this.tipoMovimentacoesOptions = await this.$store.dispatch(
          'getTipoMovimentacoes',
        )
        this.tipoVagasOptions = await this.$store.dispatch('getTipoVagas')
        this.funcoesOptions = await this.$store.dispatch('getFuncoes')

        if (this.idMovimentacao) {
          this.getMovimentacao()
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getMovimentacao() {
      try {
        this.movimentacao = await this.$store.dispatch(
          'getMovimentacao',
          this.idMovimentacao,
        )
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          let response = null

          if (this.idMovimentacao) {
            response = await this.$store.dispatch('updateMovimentacao', {
              idMovimentacao: this.idMovimentacao,
              movimentacao: this.movimentacao,
            })

            hideModal()
            this.$emit('closeModal', true)
            this.$root.$refs.notification.success(response.message)
          } else {
            response = await this.$store.dispatch(
              'createMovimentacao',
              this.movimentacao,
            )

            hideModal()
            this.$emit('closeModal', false)
            this.$root.$refs.notification.success(response.message)

            this.$router.push({
              name: 'MovimentacoesDetails',
              params: { idMovimentacao: response.data },
            })
          }
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      movimentacao: {
        IDUNIDADE: {
          required,
        },
        IDTIPOMOVIMENTACAO: {
          required,
        },
        JUSTIFICATIVA: {
          required,
        },
        PROCESSO_SELETIVO: {
          NOME: {
            requiredIfPS: requiredIf(
              TipoMovimentacaoEnum.PROCESSO_SELETIVO ===
                this.movimentacao.IDTIPOMOVIMENTACAO,
            ),
          },
          IDFUNCAO: {
            requiredIfPS: requiredIf(
              TipoMovimentacaoEnum.PROCESSO_SELETIVO ===
                this.movimentacao.IDTIPOMOVIMENTACAO,
            ),
          },
          DATAINICIOPREVISTA: {
            requiredIfPS: requiredIf(
              TipoMovimentacaoEnum.PROCESSO_SELETIVO ===
                this.movimentacao.IDTIPOMOVIMENTACAO,
            ),
          },
          IDTIPOVAGA: {
            requiredIfPS: requiredIf(
              TipoMovimentacaoEnum.PROCESSO_SELETIVO ===
                this.movimentacao.IDTIPOMOVIMENTACAO,
            ),
          },
        },
      },
    }
  },

  mounted() {
    showModal('modalCreateEditMovimentacao')

    this.getUnidadesAndTipoMovimentacoesAndTipoVagaAndFuncoes()

    if (!this.idMovimentacao) {
      this.isLoadingData = false
    }
  },
}
</script>
