<template>
  <div class="accordion-item border-bottom-0 border-start-0 border-end-0">
    <h2 class="accordion-header" title="Processo Seletivo">
      <button
        type="button"
        class="accordion-button fw-semibold"
        data-bs-toggle="collapse"
        data-bs-target="#processo-seletivo"
      >
        Processo Seletivo
      </button>
    </h2>
    <div id="processo-seletivo" class="accordion-collapse collapse show">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p>
              <strong>Nome do Processo:</strong>
              {{ movimentacao.PROCESSO_SELETIVO.NOME }}
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p>
              <strong>Função:</strong>
              {{ movimentacao.PROCESSO_SELETIVO.NOMEFUNCAO }}
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p>
              <strong>Data Prevista para Início:</strong>
              {{
                dateTimeSqlToDateBR(
                  movimentacao.PROCESSO_SELETIVO.DATAINICIOPREVISTA,
                )
              }}
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p>
              <strong>Tipo de Vaga: </strong>
              {{ movimentacao.PROCESSO_SELETIVO.NOMETIPOVAGA }}
            </p>
          </div>
          <div class="col-sm-12">
            <p class="mb-0">
              <strong>Observações:</strong>
              {{ movimentacao.PROCESSO_SELETIVO.OBSERVACAO }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeSqlToDateBR } from '@/utils/date'

export default {
  name: 'DetailsProcessoSeletivoAccordion',

  props: {
    movimentacao: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dateTimeSqlToDateBR,
    }
  },
}
</script>
