<template>
  <CreateEditMovimentacaoModal
    v-if="showCreateMovimentacaoModal"
    @closeModal="showCreateMovimentacaoModal = false"
  />
  <div class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h4 class="py-3 mb-0">Movimentações</h4>
      <div class="ms-auto my-auto">
        <button
          type="button"
          class="btn btn-primary"
          title="Cadastrar Movimentação"
          @click="showCreateMovimentacaoModal = true"
        >
          Cadastrar Movimentação
        </button>
      </div>
    </div>
    <LoadingData v-if="isLoadingData" class="my-3" />
    <ListMovimentacoes v-else :movimentacoes="movimentacoes" />
  </div>
</template>

<script>
import CreateEditMovimentacaoModal from '@/components/movimentacoes/movimentacoes/CreateEditMovimentacaoModal.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListMovimentacoes from '@/components/movimentacoes/movimentacoes/ListMovimentacoes.vue'

export default {
  name: 'Movimentacoes',

  components: {
    CreateEditMovimentacaoModal,
    LoadingData,
    ListMovimentacoes,
  },

  data() {
    return {
      isLoadingData: true,
      showCreateMovimentacaoModal: false,
      movimentacoes: [],
    }
  },

  methods: {
    async getMovimentacoes() {
      try {
        this.movimentacoes = await this.$store.dispatch('getMovimentacoes')
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getMovimentacoes()
  },
}
</script>
